import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../components/layout'
import { useState, useEffect } from 'react'
// import VideoModal from '../components/VideoModal'

const Rewards = ({ location }) => {

    return (
        <Layout location = {location}>
            
            <section class="py-5 px-3 w-full bg-indigo-100 overflow-hidden relative">
                <img class = "absolute -right-6 top-10 transform rotate-180 hidden lg:flex" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/11/rewards-ppc-dotted.png" height= "45px" width = "95px" />
                <img class = "absolute -left-6 bottom-10 hidden lg:flex" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/11/rewards-ppc-dotted.png" height= "45px" width = "95px" />
                    <div class="container max-w-7xl px-1 sm:px-6 lg:px-6">
                    <div class="site-breadcrumb mb-5 text-white opacity-80 text-center">
                    
                        
                    </div>
                    <div class="lg:flex">
                        <div class="card-1 w-full lg:w-1/2 mb-10 md:mb-0 flex flex-col justify-center lg:mt-5">
                        <h2 class = "text-gray-100 text-2xl md:text-3xl 2xl:text-4xl font-bold text-center lg:text-left"><span class = "text-orange">Reward</span> and <span class = "text-orange">Recognize</span> Your Employees Fairly to Attract and Retain Best Talents</h2>
                        
                        <p class = "text-gray-100 text-lg flex justify-center lg:justify-start md:text-base 2xl:text-xl text-left pt-8 lg:pt-16 md:pb-1 m-0 text-center lg:text-left">Best Employee Rewards and Recognition Software</p>
                        <p class = "text-orange font-bold text-gray-100 justify-center lg:justify-start text-lg md:text-base 2xl:text-xl text-left 2xl:pt-0 pb-5 m-0 text-center lg:text-left">Trusted by 400+ Global Companies</p>
                        

                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/11/02-client-logo-RewardsPPC.png" class="self-center lg:self-start" height = "80px" width = "410px" />
                        <div id="retain" class="card-1 w-full bg-white text-gray-900 rounded py-3 px-5 lg:p-5 md:p-9 transform scale-90 lg:hidden">
                                <div class="form-section relative">
                                    <h2 class="mt-2 mb-8 text-2xl font-semibold text-gray-900 text-center sm:text-3xl">Schedule a Personalized Demo Today!</h2>
                                    <div class="notetext text-center font-normal text-base text-gray-800 transform -translate-y-4"><span class = "text-orange">1.8 Million Employees Onboarded</span> Globally on the Platform within 7 Years of Employees.</div>
                                    <HubspotForm
                                        portalId='6153052'
                                        formId='d1fc98c2-2412-4dc3-97ec-56829decff7f'
                                        onSubmit={() => console.log('Submit!')}
                                        onReady={(form) => console.log('Form ready!')}
                                        loading={<div>Loading...</div>}
                                    />

                                    
                                </div>
                        </div>
                        <div class = "w-full flex justify-center items-center transform -translate-y-5 lg:hidden"><button id = "myButton" class= "flex text-gray-100 hover:text-gray-300 modal-open modal-toggle"><img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/watch-video.png" height = "18px" width="25px" />&nbsp; Watch Video</button></div>
                        <p class = "mt-10 text-gray-100 text-lg md:text-base 2xl:text-xl text-center lg:text-left"><span class = "text-orange font-bold font-bold">Loved by Clients</span> For it's Ease and Simplicity</p>
                        <div class = "w-full lg:w-5/6 flex justify-around items-center lg:justify-between mt-0 lg:mr-5 mb-5 lg:mb-9">
                            <img loading="lazy" class="hidden md:flex" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/08/best-support.png" alt="G2 award4" width="66" height="60" />

                            <img class="mx-1" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548690/gatsbycms/uploads/2021/08/crozdesk-1.png" alt="Crozdesk award1" width="66" height="60" />
                            <img class="mx-1" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664738/gatsbycms/uploads/2021/08/Software-reviews-_resized.png" alt="Software Reviews award" width="85" height="85" />
                            <img class="mx-1" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1629548691/gatsbycms/uploads/2021/08/crozdesk-4.png" alt="Crozdesk award4" width="66" height="60" />
                            
                            <img loading="lazy" class= "hidden md:flex" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/08/leader-enterprise_resized.png" alt="G2 award1" width="66" height="60" />
                        </div>
                        <div class = "w-full flex justify-center items-center md:hidden mt-2 lg:mt-6">
                            <img loading="lazy" class="mx-2" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/08/leader-enterprise_resized.png" alt="G2 award1" width="66" height="60" />
                            <img loading="lazy" class="mx-2" src="https://res.cloudinary.com/vantagecircle/image/upload/v1628664737/gatsbycms/uploads/2021/08/best-support.png" alt="G2 award4" width="66" height="60" />
                        </div>
                        
                    </div>
                        <div class="lg:w-1/2 lg:ml-28 transform lg:-translate-y-2 hidden lg:grid"> 
                            <div id="retain" class="card-1 w-full bg-white text-gray-900 rounded-lg p-5 md:p-3 md:px-6 transform scale-90">
                                <div class="form-section relative">
                                    <h2 class="mt-2 mb-8 text-2xl font-semibold text-gray-900 text-center sm:text-3xl">Schedule a Personalized Demo Today!</h2>
                                    <div class="notetext text-center font-normal text-base text-gray-800 transform -translate-y-4"><span class = "text-orange">1.8 Million Employees Onboarded</span> Globally on the Platform within 7 Years of Employees.</div>
                                    <HubspotForm
                                        portalId='6153052'
                                        formId='d1fc98c2-2412-4dc3-97ec-56829decff7f'
                                        onSubmit={() => console.log('Submit!')}
                                        onReady={(form) => console.log('Form ready!')}
                                        loading={<div>Loading...</div>}
                                    />

                                    {/* <div class="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank" rel="noopener">Privacy Policy</a>.</div> */}
                                </div>
                            </div>
                            <div class = "w-full flex justify-center items-center transform -translate-y-5"><button class= "flex text-gray-100 hover:text-gray-300 modal-open modal-toggle" id = "myButton"><img src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/watch-video.png" height = "18px" width="25px" />&nbsp; Watch Video</button></div>
                        </div>
                        
                    </div>
                </div>
            </section>

    <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                <svg class="fill-current text-white" width="30" height="30" viewBox="0 0 18 18">
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
            </button>
            <div class="modal-content text-left">
                <div class="aspect-w-16 aspect-h-9">        
                    {/* <iframe loading="lazy" id = "myIframe" src = "" class="video" title="Vantage Circle Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe> */}
                    <iframe class="video"
  width="560"
  height="315"
  src="https://www.youtube.com/embed/5Y_v1fXaPz0"
  srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/5Y_v1fXaPz0?autoplay=1><img src=https://img.youtube.com/vi/5Y_v1fXaPz0/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
  frameborder="0"
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen
  title="Vantage Circle"
></iframe>
                </div>
            </div>   
        </div>
    </div>
           
           
            <section class = "bg-purple-100">
                <div class = "container p-5 md:py-14 w-full flex flex-col lg:pt-10 justify-center items-center">
                    <h2 class = "font-bold text-2xl section-title md:text-3xl 2xl:text-4xl mt-3 mb-1 justify-center items-center text-center text-gray-800">Industries We <span class = "text-purple-500">Serve</span></h2>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 mt-8 gap-0 w-full lg:w-full xl:w-5/6 lg:mr-5">
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-27.png" width= "64" height = "64" />Retail</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-31.png" width= "64" height = "64" />Consulting</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-29.png" width= "64" height = "64" />Telecom</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-26.png" width= "64" height = "64" />BFSI</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-34.png" width= "64" height = "64" />Construction</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-35.png" width= "64" height = "64" />Logistics</h2>
                    </div>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-3 gap-0 w-full lg:w-full xl:w-5/6 2xl:w-4/6 xl:mr-0 lg:ml-2 2xl:mr-5">
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800 text-center"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-24.png" width= "64" height = "64" />Information Technology</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-6.png" width= "64" height = "64" />Pharma & Health</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-32.png" width= "64" height = "64" />Media & Digital</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800 md:hidden lg:flex text-center"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-30.png" width= "64" height = "64" />Electrical / Electronic</h2>
                    </div>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 mt-3 gap-0 w-full lg:w-4/6 xl:w-3/5 2xl:w-2/5 lg:mr-5">
                        <h2 class = "flex-col lg:flex-row justify-center items-center text-gray-800 hidden md:flex lg:hidden"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-30.png" width= "64" height = "64" />Electrical / Electronic</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800 text-center"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-33.png" width= "64" height = "64" />Non-proft Organization</h2>
                        <h2 class = "flex flex-col lg:flex-row justify-center items-center text-gray-800 text-center"><img class = "mt-2" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/10/Group-28.png" width= "64" height = "64" />Outsourcing / Offsourcing</h2>
                    </div>
                </div>
            </section>

            <section class = "w-full flex flex-col justify-center items-center mt-12 md:mt-20 px-4 py-3 md:py-9">
                <div class = "flex flex-col justify-center items-center w-full">
                    
                    <div id="recognize" class="flex flex-row-reverse w-5/6 md:w-full md:px-2 flex-wrap items-center justify-center mb-0 container">
                        <div class="lg:w-1/2 flex justify-center items center">
                            <div class = "lg:w-4/6">
                                <h3 class="text-gray-900 text-xl md:text-2xl xl:text-3xl font-bold mb-6 text-center lg:text-left lg:ml-2">
                                    <span class = "text-purple-500">Appreciate</span> Employee Efforts with the Best Employee Recognition Software
                                </h3>
                                <div class="mt-1 text-base md:text-lg">
                                    <ul class="list-inside purplebullets">
                                        <li>Spot the signs of employees hiding anxiety.</li>
                                        <li>Solutions to alleviate stress and manage uncertainty.</li>
                                        <li>Have people speak up when feeling overwhelmed.</li>
                                        <li>Beat perfectionism and get things done.</li>
                                        <li>Instill an ethic of inclusion and support.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="lg:w-1/2">
                            <div class="justify-center items-center flex flex-wrap relative text2">
                                <div class="image-wrapper">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Appreciate.png" width = "318" height = "340" alt="Appreciate"/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="reward" class="flex flex-row w-5/6 md:w-full md:px-2 flex-wrap items-center justify-center mb-0 container">
                        <div class="lg:w-1/2 flex justify-center items center">
                            <div class = "lg:w-4/6">
                                <h3 class="text-gray-900 text-xl md:text-2xl xl:text-3xl font-bold mb-6 text-center lg:text-left lg:ml-2">
                                    <span class = "text-purple-500">Reward</span> Them Fairly to Motivate them for Best Performance
                                </h3>
                                <div class="mt-1 text-base md:text-lg">
                                    <ul class="list-inside purplebullets">
                                        <li>Reward every effort of your employees</li>
                                        <li>Encourage employees to hone their skills</li>
                                        <li>Inculcate a positive reinforcement work culture</li>
                                        <li>Enhance employee loyalty & retention</li>
                                        <li>Drive employee engagement</li>
                                        <li>Improve inter-channel communication</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="lg:w-1/2">
                            <div class="justify-center items-center flex flex-wrap relative text2">
                                <div class="image-wrapper">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Rewards.png" width = "318" height = "315" alt="Rewards"/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="celebrate" class="flex flex-row-reverse w-5/6 md:w-full md:px-2 flex-wrap items-center justify-center mb-0 container">
                        <div class="lg:w-1/2 flex justify-center items center">
                            <div class = "lg:w-4/6">
                                <h3 class="text-gray-900 text-xl md:text-2xl xl:text-3xl font-bold mb-6 text-center lg:text-left lg:ml-2">
                                    <span class = "text-purple-500">Celebrate</span> Every Occasion Together with Easy Gifting Options
                                </h3>
                                <div class="mt-1 text-base md:text-lg">
                                    <ul class="list-inside purplebullets">
                                        <li>Build long lasting & meaningful relationships with employees</li>
                                        <li>Strike up a bond with new joinees</li>
                                        <li>Create a feeling of bonhomie among employees</li>
                                        <li>Implement an employee-first corporate culture</li>
                                        <li>Set yourself apart from competitors</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="lg:w-1/2">
                            <div class="justify-center items-center flex flex-wrap relative text2">
                                <div class="image-wrapper">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Celebrate.png" width = "318" height = "359" alt="Celebrate"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div id="service" class="flex flex-row w-5/6 md:w-full md:px-2 flex-wrap items-center justify-center mb-0 container">
                        <div class="lg:w-1/2 flex justify-center items center">
                            <div class = "lg:w-4/6">
                                <h3 class="text-gray-900 text-xl md:text-2xl xl:text-3xl font-bold mb-6 text-center lg:text-left lg:ml-2">
                                    Make Work Anniversaries Extra Special with<span class = "text-purple-500"> Service Year Book</span> 
                                </h3>
                                <div class="mt-1 text-base md:text-lg">
                                    <ul class="list-inside purplebullets">
                                        <li>Reward every effort of your employees</li>
                                        <li>Encourage employees to hone their skills</li>
                                        <li>Inculcate a positive reinforcement work culture</li>
                                        <li>Enhance employee loyalty & retention</li>
                                        <li>Drive employee engagement</li>
                                        <li>Improve inter-channel communication</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="lg:w-1/2">
                            <div class="justify-center items-center flex flex-wrap relative text2">
                                <div class="image-wrapper">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Service-Yearbook.png" height = "318" width = "307" alt="Service Yearbook"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="insights" class="flex flex-row-reverse w-5/6 md:w-full md:px-2 flex-wrap items-center justify-center mb-0 container">
                        <div class="md:w-4/6 lg:w-1/2 flex justify-center items center container">
                            <div class = "lg:w-4/6">
                                <h3 class="text-gray-900 text-xl md:text-2xl xl:text-3xl font-bold mb-6 text-center lg:text-left lg:ml-2">
                                    Understand Your Employees Better with <span class = "text-purple-500">Real Time Insights</span> 
                                </h3>
                                <div class="mt-1 text-base md:text-lg">
                                    <ul class="list-inside purplebullets">
                                        <li>Record & analyze every response by employees</li>
                                        <li>Build an effective, action-oriented, people-first culture</li>
                                        <li>Get insights into employee problems & plan better solutions</li>
                                        <li>Understand criterias of employee engagement to improve employee experience</li>
                                        <li>Make your employees feel heard with frequent surveys</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="lg:w-1/2">
                            <div class="justify-center items-center flex flex-wrap relative text2">
                                <div class="image-wrapper">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Insights.png" height = "318" width = "311" alt="Insights"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class = " flex justify-center items-center mt-9">
                    <a href="#retain" class = "vc-colored-btn">Talk to Us</a>
                </div>
            </section>


            <section id="integrate" class = "w-full flex justify-center items-center mt-12 px-4 py-3 md:py-14 bg-purple-100">
                <div class = "w-full px-3 md:w-4/6 flex flex-col justify-center items-center">
                    <h2 class = "font-bold text-2xl section-title md:text-3xl 2xl:text-4xl mt-5 mb-5 justify-center items-center text-center text-gray-800">
                        We Enable You to <span class = "text-orange">Experience Seamless & Secure Integration</span> of Data on Existing Platforms
                    </h2>
                    <div class = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 justify-center items-center w-full mt-9 mb-1">
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-14.png" height = "128" width = "206" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-38.png" height = "127" width = "205" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-39.png" height = "128" width = "207" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-40.png" height = "127" width = "205" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-41.png" height = "128" width = "207" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-42.png" height = "129" width = "207" /></div>
                    </div>

                    <div class = "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 justify-center items-center w-full lg:w-5/6 my-1">
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-8.png" height = "129" width = "206" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-9.png" height = "129" width = "206" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-37.png" height = "128" width = "206" /></div>
                        <div class = "w-full flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-43.png" height = "128" width = "206" /></div>
                        <div class = "w-full flex md:hidden lg:flex justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-44.png" height = "128" width = "206" /></div>
                    </div>
                    <div class = "w-full hidden md:flex lg:hidden justify-center items-center"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/Group-44.png" height = "128" width = "206" /></div>
                </div>
            </section>

            <section class = "w-full flex justify-center items-center mt-12 px-4 py-3 md:py-9">
                <div class = "w-full px-3 md:w-5/6 xl:w-full flex flex-col justify-center items-center">
                    <h2 class = "font-bold section-title text-2xl md:text-3xl 2xl:text-4xl mt-5 mb-5 justify-center items-center text-center text-gray-800">
                        Our Clients Have Helped Us Reach the <span class="text-orange"> Top of the G2 Crowd Grid</span>
                    </h2>
                    <h3 class = "text-sm 2xl:text-md mt-5 md:mt-12 mb-3 justify-center items-center text-center font-bold text-gray-800">Employee Grid For Employee Recognition Software</h3>
                    <div class = "w-full xl:w-5/6 flex justify-center items-center">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/10/G2-Grid-1.png" width = "458" height = "221" />
                    </div>
                </div>
            </section>


            <section id="g2awards" class="container md:max-w-4xl lg:max-w-7xl mx-auto px-6 text-center py-10 md:pt-2 md:pb-0 relative">
                <div class="flex justify-around items-center items-start w-full h-auto">
                    <div class="flex flex-col w-full lg:flex-row md:justify-around items-center mb-6 md:mb-3 transform lg:scale-90">
                        <div class="flex flex-col justify-center items-center transform scale-105 mx-3 lg:scale-100 mt-12 md:mt-10 lg:mt-0"><div id="percentCircle1"></div><p class="text-gray-900 mt-2 rounded-sm shadow-lg w-48 md:h-20 p-3 flex justify-center items-center text-center font-semibold">Ease of doing Business</p></div>
                        <div class="flex flex-col justify-center items-center transform scale-105 mx-3 lg:scale-100 mt-12 md:mt-10 lg:mt-0"><div id="percentCircle2"></div><p class="text-gray-900 mt-2 rounded-sm shadow-lg w-48 md:h-20 p-3 flex justify-center items-center text-center font-semibold">Likelihood to Recommend</p></div>
                        <div class="flex flex-col justify-center items-center transform scale-105 mx-3 lg:scale-100 mt-12 md:mt-10 lg:mt-0"><div id="percentCircle3"></div><p class="text-gray-900 mt-2 rounded-sm shadow-lg w-48 md:h-20 p-3 flex justify-center items-center text-center font-semibold">Quality of Support</p></div>
                        <div class="flex flex-col justify-center items-center transform scale-105 mx-3 lg:scale-100 mt-12 md:mt-10 lg:mt-0"><div id="percentCircle4"></div><p class="text-gray-900 mt-2 rounded-sm shadow-lg w-48 md:h-20 p-3 flex justify-center items-center text-center font-semibold">Ease of Setup</p></div> 
                        <div class="flex flex-col justify-center items-center transform scale-105 mx-3 lg:scale-100 mt-12 md:mt-10 lg:mt-0"><div id="percentCircle5"></div><p class="text-gray-900 mt-2 rounded-sm shadow-lg w-48 md:h-20 p-3 flex justify-center items-center text-center font-semibold">Ease of Use</p></div> 
                    </div>
                </div>
            </section>

            <section class="relative bg-indigo-100 border-b-2 stats-empeng no-br mt-10 lg:mt-40 pt-14 pb-8 sm:pb-20 md:py-20 md:pb-10">
                <div class="container max-w-7xl mx-auto px-6 mx-auto flex md:flex-nowrap flex-wrap items-center justify-center text-white">
                    <div class="w-full md:w-2/5 mobzind">
                        <div class="lg:text-left pb-5">
                            <div class="section-label">Global Capabilities</div>
                            <h2 class="section-title-white">
                                Grow with Us
                            </h2>
                        </div>
                        <div class="flex">
                            <div class="mt-3 md:mt-5">
                                <div class="marker circle-base relative"><div class="pulse"></div></div>
                                <div class="dashedlines"></div>
                                <div class="marker circle-base relative"><div class="circle2 pulse"></div></div>
                                <div class="marker circle-base relative"><div class="circle3 pulse"></div></div>
                                <div class="marker circle-base relative"><div class="circle4 pulse"></div></div>
                            </div>
                            <div class="ml-8 md:ml-14">
                                <div class="mb-8">
                                    <div class="section-title-orange">6K+</div>
                                    <p class="text-white">Reward Options</p>
                                </div>
                                <div class="mb-8">
                                    <div class="section-title-orange">59+</div>
                                    <p class="text-white">Countries</p>
                                </div>
                                <div class="mb-8">
                                    <div class="section-title-orange">400+</div>
                                    <p class="text-white">Companies</p>
                                </div>
                                <div class="mb-10">
                                    <div class="section-title-orange">1.8M+</div>
                                    <p class="text-white">Corporate Employees</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-3/5 relative mob-stats">
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1621511045/gatsbycms/uploads/2021/05/worldmap.svg" alt="Global Capabilities" />
                        <div class="loc-icon loc-icon1"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1621511041/gatsbycms/uploads/2021/05/location-pin.svg" width= "40" height = "40" alt="locator-icon" /></div>
                        <div class="loc-icon loc-icon2"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1621511041/gatsbycms/uploads/2021/05/location-pin.svg" width= "40" height = "40" alt="locator-icon" /></div>
                        <div class="loc-icon loc-icon3"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1621511041/gatsbycms/uploads/2021/05/location-pin.svg" width= "40" height = "40" alt="locator-icon" /></div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Rewards
